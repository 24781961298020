<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课程列表</span>
    </div>
    <span>拖动以下列表中的数据可以排序</span>
    <el-table
      :data="courseList"
      style=";margin-bottom: 20px;margin-top: 15px"
      row-key="id"
      border
      ref="dragTable"
      default-expand-all
    >
      <el-table-column prop="title" label="课程名称" align="center"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column prop="status" label="发布状态" align="center"></el-table-column>
      <el-table-column prop="creatorName" label="创建者" align="center"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据 和 弹框标题
 * @attribute cascaderList  el-cascader 级联选择器数据
 * @function close 自定义弹窗关闭事件
 */
import { getCourseListAll , saveCourseSeqByCatalog} from '@/api/research/curriculum'
import Sortable from 'sortablejs'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    catalogId: {
      default: '',
      type: String
    },
  },
  components: {
    Sortable
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return{
      courseList:[],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      courseListThemb:[],
      oldList:[],
      newList:[],
    }
  },
  methods: {
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: (dataTransfer) => {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.courseList.splice(evt.oldIndex, 1)[0]
          this.courseList.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
          this.newList.splice(evt.newIndex, 0, tempIndex)
          this.saveCourseSeqByCatalog()
          this.$forceUpdate()
        }
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getCourseListAll();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getCourseListAll();
    },

    async getCourseListAll () {
      const res = await getCourseListAll({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount:true,
        catalogId:this.catalogId,
        orderBy:'c.seq'
      })
      this.courseList = res.body.list
      this.pagination.total = res.body.total
      this.courseListThemb = []
      res.body.list.forEach((item)=> {
        this.courseListThemb.push(item)
      })

      this.oldList = this.courseList.map(v => v.id)
      this.newList = this.oldList.slice()
      this.$nextTick(() => {
        this.setSort()
      })
    },
    async saveCourseSeqByCatalog () {
      const res = await saveCourseSeqByCatalog({
        catalogId:this.catalogId,
        courseIds:this.newList,
      })
      if (res && res.state === 'success') {
        window.$msg('排序成功')
        this.getCourseListAll()
      }
    },

    openDialog () {
      this.getCourseListAll()
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("el-row", { staticStyle: { "margin-bottom": "30px" } }, [
        _c("span", { staticClass: "title" }, [_vm._v("课程分类管理")]),
      ]),
      _c(
        "el-col",
        { attrs: { span: 7 } },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "14px",
                "font-weight": "bold",
                "margin-right": "20px",
              },
            },
            [_vm._v("编程课程")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.addCategory(1)
                },
              },
            },
            [_vm._v("新 增")]
          ),
          _c(
            "el-table",
            {
              ref: "dragTable",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.bcList,
                "row-key": "id",
                border: "",
                "default-expand-all": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "分类名", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editCategory(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("编 辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.previewCategory(scope.row)
                              },
                            },
                          },
                          [_vm._v("课程排序")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 6, offset: 1 } },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "14px",
                "font-weight": "bold",
                "margin-right": "20px",
              },
            },
            [_vm._v("培训课程")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.addCategory(2)
                },
              },
            },
            [_vm._v("新 增")]
          ),
          _c(
            "el-table",
            {
              ref: "dragTable",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pxList,
                "row-key": "id",
                border: "",
                "default-expand-all": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "分类名", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editCategory(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v("编 辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.previewCategory(scope.row)
                              },
                            },
                          },
                          [_vm._v("课程排序")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-course-category", {
        attrs: {
          show: _vm.categoryDialog,
          editData: _vm.editData,
          addFlag: _vm.addFlag,
          editFlag: _vm.editFlag,
        },
        on: {
          close: function ($event) {
            _vm.categoryDialog = false
            _vm.editData = null
          },
          updateCourseCatalog: _vm.updateCourseCatalog,
        },
      }),
      _c("preview-course-category", {
        attrs: { show: _vm.previewCategoryDialog, catalogId: _vm.catalogId },
        on: {
          close: function ($event) {
            _vm.previewCategoryDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
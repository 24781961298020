var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("课程列表")])]
      ),
      _c("span", [_vm._v("拖动以下列表中的数据可以排序")]),
      _c(
        "el-table",
        {
          ref: "dragTable",
          staticStyle: { "margin-bottom": "20px", "margin-top": "15px" },
          attrs: {
            data: _vm.courseList,
            "row-key": "id",
            border: "",
            "default-expand-all": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "title", label: "课程名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "发布状态", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "creatorName", label: "创建者", align: "center" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
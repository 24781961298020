<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="categoryTitle"></span>
    </div>
    <!-- editData：{{editData}}
    <hr>
    formData：{{formData}}-->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="课程分类：" prop="name">
        <el-input v-model="formData.name" placeholder="请输入课程分类" maxlength="20"></el-input>
      </el-form-item>
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据 和 弹框标题
 * @attribute cascaderList  el-cascader 级联选择器数据
 * @function close 自定义弹窗关闭事件
 */
import { courseCatalogAdd, courseCatalogEdit } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    addFlag: {
      default: 0,
      type: Number
    },
    editFlag: {
      default: 0,
      type: Number
    },
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      categoryTitle: '',
      formData: {},
      rules: {
        name: [
          { required: true, message: '课程系列名不能为空', trigger: 'blur' },
          { max: 20, message: '字数限制在20个字内', trigger: 'blur' }
        ],
        courseGroup:[{ required: true, message: '课时单价不能为空', trigger: 'blur'}],
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {}
      if (this.editData !== null) {
        this.categoryTitle = '编辑课程分类'
        this.formData = { ...this.editData }
      } else {
        this.categoryTitle = '新增课程分类'
      }
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 保存
    saveCategory () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.editData !== null) {
            courseCatalogEdit({
              id:this.formData.id,
              title:this.formData.name,
              courseGroup:this.editFlag === 1 ? '编程' : '培训'
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateCourseCatalog')
              }
            })
          } else {
            courseCatalogAdd({
              title:this.formData.name,
              courseGroup:this.addFlag === 1 ? '编程' : '培训'
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateCourseCatalog')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

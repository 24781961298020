import $axios from '@/utils/request'


//复制课节
export function cloneCourseUnitToAnotherCourse (data) {
  const url = '/courseUnit/cloneCourseUnitToAnotherCourse'
  return $axios.fPost(url, data)
}
// 获取python单任务多题目
export function getTaskTopicList (data) {
  const url = '/task/item/getList'
  return $axios.fGet(url, data)
}
// 获取课程列表
export function getCourseList (data) {
  const url = '/course/list'
  return $axios.fPost(url, data)
}

// 获取所有课程列表
export function getCourseListAll (data) {
  const url = '/course/listAll'
  return $axios.fPost(url, data)
}

// 针对于课程分类的课程排序
export function saveCourseSeqByCatalog (data) {
  const url = '/courseCatalog/saveCourseSeqByCatalog'
  return $axios.fPost(url, data)
}
//在线文档查看
export function previewFile (data) {
  const url = '/common/file/preview'
  return $axios.fInfoPost(url, data)
}
// 设置任务的发布状态
export function setTaskStatus (data) {
  const url = '/task/setStatus/'
  return $axios.fPost(url, data)
}

// 设置课节的发布状态
export function setCourseUnitStatus (data) {
  const url = '/courseUnit/setStatus'
  return $axios.fPost(url, data)
}

// 获取课程类别
export function courseCatalog (data) {
  const url = '/courseGoods/list'
  return $axios.fPost(url, data)
}

// 获取课程分类
export function getCatalogTree (data) {
  const url = '/courseCatalog/getCatalogTree'
  return $axios.fGet(url, data)
}

// 增加课程分类
export function courseCatalogAdd (data) {
  const url = '/courseCatalog/add'
  return $axios.fPost(url, data)
}

// 编辑课程分类
export function courseCatalogEdit (data) {
  const url = '/courseCatalog/edit'
  return $axios.fPost(url, data)
}

// OJ-重判题目
export function resetSolution (data) {
  const url = '/question/resetSolution'
  return $axios.fPost(url, data)
}
// 新增课程
export function addCourse (data) {
  const url = '/course/add'
  return $axios.fPost(url, data)
}

// 编辑课程/修改课程状态
export function editCourse (data) {
  const url = '/course/edit'
  return $axios.fPost(url, data)
}

// 删除课程
export function delCourse (data) {
  const url = '/course/del'
  return $axios.fPost(url, data)
}

// 获取课程基本信息
export function getCourseInfo (data) {
  const url = '/course/getInfo'
  return $axios.fPost(url, data)
}

// 获取(课节, 任务) 列表
export function getTaskList (data) {
  const url = '/task/list'
  return $axios.fPost(url, data)
}

// 获取课节列表
export function getCourseUnitList (data) {
  const url = '/courseUnit/list'
  return $axios.fPost(url, data)
}

// 课程 -> 新增课节
export function addCourseUnit (data) {
  const url = '/courseUnit/add'
  return $axios.fPost(url, data)
}

// 课程 -> 编辑课节
export function editCourseUnit (data) {
  const url = '/courseUnit/edit'
  return $axios.fPost(url, data)
}

// 课程 -> 删除课节
export function delCourseUnit (data) {
  const url = '/courseUnit/del'
  return $axios.fPost(url, data)
}

// 课程 -> 课节 -> 添加任务
export function addTask (data) {
  const url = '/task/add'
  return $axios.fPost(url, data)
}

// 课程 -> 课节 -> 编辑任务
export function editTask (data) {
  const url = '/task/edit'
  return $axios.fPost(url, data)
}

// 课程 -> 课节 -> 删除任务
export function delTask (data) {
  const url = '/task/del'
  return $axios.fPost(url, data)
}

// 课程 -> 课节 -> 任务详细信息
export function getTaskInfo (data) {
  const url = '/task/getInfo'
  return $axios.fPost(url, data)
}

// 课节排序
export function dragSort (data) {
  const url = '/courseUnit/reSort'
  return $axios.fPost(url, data)
}

// 排序
export function taskSort (data) {
  const url = '/task/reSort'
  return $axios.fPost(url, data)
}

// 添加 课件 / 补课视频
export function addAttachment (data) {
  const url = '/courseUnit/addAttachment'
  return $axios.fPost(url, data)
}
// 更新 课件
export function editAttachment (data) {
  const url = '/courseUnit/editAttachment'
  return $axios.fPost(url, data)
}

//互动视频配置列表
export function getInteractiveConfigById (data) {
  const url = '/courseUnit/getInteractiveConfigById'
  return $axios.fPost(url, data)
}

// 删除 课件
export function delCourseWare (data) {
  const url = '/courseUnit/delCourseWare'
  return $axios.fPost(url, data)
}

// 添加 课件 / 补课视频
export function resetAttachment (data) {
  const url = '/courseUnit/resetAttachment'
  return $axios.fPost(url, data)
}

// 新增课程类别
export function addCatalog (data) {
  const url = '/courseGoods/add'
  return $axios.fPost(url, data)
}

// 编辑课程类别
export function editCatalog (data) {
  const url = '/courseGoods/edit'
  return $axios.fPost(url, data)
}

// 获取绑定得课程列表
export function getBindCourseList (data) {
  const url = '/courseGoods/getBindCourseList'
  return $axios.fGet(url, data)
}

// 系列 下课程排序
export function reSortGroup (data) {
  const url = '/courseGoods/reSortGroup'
  return $axios.fPost(url, data)
}

// 系列 排序
export function reSort (data) {
  const url = '/courseGoods/reSort'
  return $axios.fPost(url, data)
}

// 删除课程类别
export function delCatalog (data) {
  const url = '/courseCatalog/del'
  return $axios.fPost(url, data)
}

// 获取课件列表
export function getListAttachment (data) {
  const url = '/courseUnit/listAttachment'
  return $axios.fPost(url, data)
}

// 删除课件
export function delAttachment (data) {
  const url = '/courseUnit/delAttachment'
  return $axios.fPost(url, data)
}

// 根据类别获取用户列表
export function getUserByCatalog (data) {
  const url = '/catalog/user/getUserByCatalog'
  return $axios.fPost(url, data)
}

// 批量添加类别用户
export function addUserByCatalog (data) {
  const url = '/catalog/user/addUserByCatalog'
  return $axios.fPost(url, data)
}

// 批量删除类别用户
export function deleteCatalogUser (data) {
  const url = '/catalog/user/deleteCatalogUser'
  return $axios.fPost(url, data)
}

// 课节附件排序
export function reSortAttachment (data) {
  const url = '/courseUnit/reSortAttachment'
  return $axios.fPost(url, data)
}

// 编辑教师课程费
export function editTeacherFees (data) {
  const url = '/course/editTeacherFees'
  return $axios.fPost(url, data)
}

// 获取课程学员列表
export function getUserListByCourse (data) {
  const url = '/course/getUserListByCourse'
  return $axios.fPost(url, data)
}

// 用户课程列表
export function getCourseUserWhithTaskInfo (data) {
  const url = '/course/user/getCourseUserWhithTaskInfo'
  return $axios.fPost(url, data)
}

// 修改用户培训使用状态
export function updateUseState (data) {
  const url = '/course/user/updateUseState'
  return $axios.fPost(url, data)
}

// 修改用户培训使用状态
export function batchCourseUser (data) {
  const url = '/course/user/batchCourseUser'
  return $axios.fPost(url, data)
}

// 获取商户员工列表(含总部)
export function getMerchantListWhithEmployee (data) {
  const url = '/merchant/getMerchantListWhithEmployee'
  return $axios.fGet(url, data)
}
// 获取课程用户（限定课程）
export function getUserListByCourseTrain (data) {
  const url = '/course/user/getUserListByCourse'
  return $axios.fGet(url, data)
}

// 设置课程学员状态
export function setStudentStatus (data) {
  const url = '/course/setStudentStatus'
  return $axios.fPost(url, data)
}

// 复制课程
export function cloneCourse (data) {
  const url = '/course/clone'
  return $axios.fGet(url, data)
}

export function getExtList (data) {
  const url = '/other/getExtList'
  return $axios.fGet(url, data)
}

export function setInfo (data) {
  const url = '/task/setInfo'
  return $axios.fPost(url, data)
}

export function getUploadTaskInfo (data) {
  const url = '/task/getUploadTaskInfo'
  return $axios.fGet(url, data)
}

export function listTaskUpload (data) {
  const url = '/task/listTaskUpload'
  return $axios.fPost(url, data)
}

export function delUpload (data) {
  const url = '/task/delUpload'
  return $axios.fGet(url, data)
}

// 开放课程给学员
export function mapStu (data) {
  const url = '/course/mapStu'
  return $axios.fGet(url, data)
}

// 获取积分榜
export function ranking (data) {
  const url = '/course/ranking'
  return $axios.fPost(url, data)
}

// 获取可操作课程类别
export function getCatalogByUser (data) {
  const url = '/catalog/user/getCatalogByUser'
  return $axios.fPost(url, data)
}


// 获取课程系列信息根据课程

export function $getCourseGoodsByCourse (data) {
  const url = '/courseGoods/getCourseGoodsByCourse'
  return $axios.fGet(url, data)
}

// 获取课程类别树结构

export function $getCatalogTree (data) {
  const url = '/courseCatalog/getCatalogTree'
  return $axios.fGet(url, data)
}
// 题集题目列表
export function getSimpleQuestionList (data) {
  const url = '/question/getSimpleQuestionList'
  return $axios.fPost(url, data)
}
// 新增刷题题集
export function addContestLibrary (data) {
  const url = '/questionlibrary/addContestLibrary'
  return $axios.fPost(url, data)
}
// 题集列表
export function getContestLibraryList (data) {
  const url = '/questionlibrary/getContestLibraryList'
  return $axios.fPost(url, data)
}
// 删除刷题题集
export function deleteContestLibrary (data) {
  const url = '/questionlibrary/deleteContestLibrary'
  return $axios.fGet(url, data)
}
// 修改刷题题集
export function updateContestLibrary (data) {
  const url = '/questionlibrary/updateContestLibrary'
  return $axios.fPost(url, data)
}
<template>
  <el-row class="table">

    <el-row style="margin-bottom: 30px">
      <span class="title">课程分类管理</span>
    </el-row>

    <el-col :span = '7'>
      <span style="font-size: 14px;font-weight: bold;margin-right: 20px">编程课程</span>
      <el-button type="primary" size="mini" @click="addCategory(1)" class="tb-button">新 增</el-button>
    <el-table
      :data="bcList"
      style="margin-top: 10px;"
      row-key="id"
      ref="dragTable"
      border
      default-expand-all
    >
      <el-table-column prop="name" label="分类名" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="editCategory(scope.row,1)">编 辑</el-button>
          <el-button type="text" @click="previewCategory(scope.row)">课程排序</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-col>
    <el-col :span = '6' :offset="1">
      <span style="font-size: 14px;font-weight: bold;margin-right: 20px">培训课程</span>
      <el-button type="primary" size="mini" @click="addCategory(2)" class="tb-button">新 增</el-button>
      <el-table
        :data="pxList"
        style="margin-top: 10px;"
        row-key="id"
        ref="dragTable"
        border
        default-expand-all
      >
        <el-table-column prop="name" label="分类名" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editCategory(scope.row,2)">编 辑</el-button>
            <el-button type="text" @click="previewCategory(scope.row)">课程排序</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <!-- 新增课程类别 -->
    <add-course-category
      :show="categoryDialog"
      @close="categoryDialog=false;editData=null"
      :editData="editData"
      :addFlag = 'addFlag'
      :editFlag = 'editFlag'
      @updateCourseCatalog="updateCourseCatalog"
    ></add-course-category>

    <preview-course-category
      :show="previewCategoryDialog"
      @close="previewCategoryDialog=false;"
      :catalogId = 'catalogId'
    ></preview-course-category>
  </el-row>
</template>

<script>
import addCourseCategory from '@/components/research/addCourseCategory'
import { getCatalogTree } from '@/api/research/curriculum'
import previewCourseCategory from '@/components/research/previewCourseCategory'

export default {
  components: {
    addCourseCategory,
    previewCourseCategory
  },

  data () {
    return {
      previewCategoryDialog:false,
      addFlag:1,
      editFlag:1,
      courseGoodsId:'',
      categoryDialog: false,
      editData: null,
      catalogId: '',
      bcList:[],
      pxList:[],
    }
  },
  created() {
    this.getCatalogTree('培训')
    this.getCatalogTree('编程')
  },
  methods: {
    // 添加课程类别
    addCategory (flag) {
      this.addFlag = flag
      this.categoryDialog = true
    },
    previewCategory(row) {
      this.previewCategoryDialog = true
      this.catalogId = row.id
    },
    // 编辑
    editCategory (row,flag) {
      this.editData = row
      this.editFlag = flag
      this.categoryDialog = true
    },

    async getCatalogTree (type) {
      const res = await getCatalogTree({
        group:type
      })
      if(type === '编程') {
        this.bcList = res.body
      }else if(type === '培训') {
        this.pxList = res.body
      }
    },
    // 操作完成,更新表格数据
    updateCourseCatalog () {
      this.getCatalogTree('培训')
      this.getCatalogTree('编程')
    },

  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
</style>
